/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
// Components
// Vendor
// Extras
@import 'components/shame';